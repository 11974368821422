import { LoadingButton } from "@mui/lab"
import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserDetails, listSeekers, setItem, updateUserProfile, userProfileUpdateReset, verifyUser } from "../../../actions/userActions";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import UploadButton from "../../../UI/UploadButton";
import { sanitizeInput } from "../../../utils/Validate";
import SearchBar from "../../../UI/SearchBar";
import UserHighlight from "../UserHighlight";





const Profile = () => {


    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [overview, setOverview] = useState('');
    const { user } = useSelector((state) => state.userDetails);
    const [avatar, setAvatar] = useState("")
    const [proof, setProof] = useState("")
     const [isProofUploaded, setIsProofUploaded] = useState(false);
    const [isAvatarUploaded, setIsAvatarUploaded] = useState(false);
    const [uploading, setUploading] = useState(false)
    const [filter, setFilter] = useState("")
    const { id } = useParams()





    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo.type
    const { success: successVerify, loading: loadingVerify } = useSelector((state) => state.userVerify);
    const { success, loading } = useSelector((state) => state.userProfileUpdate);
    const { seekers, loading: loadingSeekers } = useSelector(state => state.seekerList)
     const [avatarLoading, setAvatarLoading] = useState(false)

    const updateUserHandler = (e) => {
        e.preventDefault();

        try {
            dispatch(updateUserProfile({ id: user._id, firstname, lastname, company, linkedin, avatar, proof, overview }));


        } catch (error) {
            return toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

    };

    const uploadAvatarHandler = async (e) => {
        const file = e.target.files[0];
    
        if (!file) {
            console.error("❌ No file selected");
            return;
        }
    
        const formData = new FormData();
        formData.append('avatar', file);
        setAvatarLoading(true);
    
        console.log("📌 Uploading file:", file.name);
    
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };
    
            const { data } = await axios.post('/api/upload/avatar', formData, config);
    
            console.log("✅ Avatar uploaded, filePath:", data.filePath);
    
            setAvatar(data.filePath); // Store the correct file path
            setIsAvatarUploaded(true)
            setAvatarLoading(false);
            return toast("Avatar Uploaded successfuly", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
        } catch (error) {
            console.error("❌ Upload failed:", error);
            setAvatarLoading(false);
            setIsAvatarUploaded(false)
            return toast("Failed to Upload File", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }
    };
    
    


    const uploadProofHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('proof', file)
        setUploading(true)


        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const res = await axios.post('/api/upload/proof', formData, config)

            setUploading(false)
            setIsProofUploaded(true)
            setProof(res.data)
            if (res.data.success) {
                return toast("Proof Uploaded successfuly", {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }


        } catch (error) {
            console.error(error)
            setUploading(false)
            setIsProofUploaded(false)
            if (error.response && error.response.data) {
                console.log(error.response.data);
                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    }


    const viewProof = async () => {
        try {
            console.log("✅ Attempting to fetch Proof...");

            // Extract only the filename from the stored path
            // Get only the file name

            const response = await fetch(`/api/upload/view/proof`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userInfo.token}`,
                },
            });

            console.log("🔄 Fetch response received");

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                console.log("✅ Proof fetched successfully, opening...");
                window.open(url, '_blank');
            } else {
                console.error('❌ Failed to fetch proof:', response.statusText);
                const errorData = await response.json();
                console.error('❌ Error details:', errorData);
            }
        } catch (error) {
            console.error('❌ Error fetching proof:', error);
        }
    };



    useEffect(() => {
        if (filter) {
            dispatch(listSeekers(filter))
        }


        if ((user.profileId !== userInfo.profileId) || success || isAvatarUploaded || isProofUploaded) {
            dispatch(userProfileUpdateReset());
            dispatch(getUserDetails('profile'));
        } else {
            setFirstname(user.firstname);
            setLastname(user.lastname);
            setCompany(user.company);
            setLinkedin(user.linkedin);
            setAvatar(user.avatar)
            setProof(user.proof)
            setOverview(user.overview)
        }

        if (success) {
            toast("Profile Updated Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            })
        }

        if (successVerify) {
            toast("Verification Successful", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            })
        }
    }, [dispatch, user, userInfo, success, successVerify, filter, isAvatarUploaded, isProofUploaded])


    return (
        <Grid container mt={1}>
            <ToastContainer />

            <Grid xs={7} >
                {filter ? loadingSeekers ? <Typography><CircularProgress /></Typography> : seekers?.map(seeker => (
                    <UserHighlight {...seeker} />
                )) : (
                    <Box >

                        <form onSubmit={updateUserHandler}>



                            <div className="personal-image" >
                                <label className="label">
                                    <input type="file" onChange={uploadAvatarHandler} />
                                    <figure className="personal-figure">
                                        <img src={avatar} className="personal-avatar" alt="avatar" />
                                        <figcaption className="personal-figcaption">
                                            <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" alt="Camera" />
                                        </figcaption>
                                    </figure>
                                </label>
                                {avatarLoading && <Typography><CircularProgress /></Typography>}
                            </div>







                            {uploading && <Typography>Uploading...</Typography>}

                            <TextField
                                margin="normal"

                                fullWidth

                                label="First Name"
                                value={sanitizeInput(firstname)}
                                onChange={(e) => setFirstname(e.target.value)}

                                autoComplete="First Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"

                                fullWidth

                                label="Last name"
                                value={sanitizeInput(lastname)}
                                onChange={(e) => setLastname(e.target.value)}

                                autoComplete="Last name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                disabled
                                fullWidth
                                type="email"

                                value={user && user.email}
                                autoComplete="Email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"

                                fullWidth
                                onChange={(e) => setCompany(e.target.value)}
                                label="Company Name"
                                value={sanitizeInput(company)}

                                autoFocus
                            />
                            <TextField
                                margin="normal"

                                fullWidth

                                label="LinkedIn Profile"
                                value={linkedin}
                                onChange={(e) => setLinkedin(e.target.value)}
                                autoComplete="LinkedIn"
                                autoFocus
                            />

                            <TextField
                                onChange={(e) => setOverview(e.target.value)}
                                label="Company Overview"
                                fullWidth
                                multiline
                                value={overview && sanitizeInput(overview)}
                                rows={4}
                                placeholder="Write a brief description of your company here"
                                sx={{ my: 2 }}
                            />

                            <Typography variant="h6" my={2}>Upload CAC or any Government Issued ID (PDF ONLY)</Typography>


                            <Box my={3}>
                                <UploadButton fullWidth={true} text="Upload Document" onChange={uploadProofHandler} loading={uploading} />
                            </Box>


                            <div>
                                <Button
                                    variant="outlined"
                                    startIcon={<DownloadIcon />}
                                    fullWidth
                                    onClick={viewProof}
                                    
                                >Download Document</Button>
                            </div>

                            <Typography sx={{ textAlign: "center", mt: 5 }}>
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    variant="contained"
                                    className="button-primary"
                                    sx={{ my: 3 }}
                                >
                                    Save Profile
                                </LoadingButton>
                            </Typography>
                        </form>

                        {(userType === "PortalAdmin" && !user.verified) && (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Button
                                    variant="text"
                                    startIcon={<KeyboardBackspaceOutlinedIcon />}
                                    onClick={() => {
                                        setItem("users")
                                        navigate("/users")
                                    }}
                                >Back</Button>

                                <LoadingButton
                                    variant="contained"
                                    className="button-primary"

                                    endIcon={<ThumbUpOffAltIcon />}
                                    onClick={() => dispatch(verifyUser(id))}
                                    loading={loadingVerify}
                                >Approve Employer</LoadingButton>

                            </div>
                        )}
                    </Box>
                )}

            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={4} mt={3} >
                <SearchBar
                    icon="search"
                    placeholder="Search Talents By Roles"
                    name="title" value={filter} onChange={(e) => setFilter(e.target.value)}
                >
                    <SearchIcon />
                </SearchBar>
            </Grid>
        </Grid>
    )
}




export default Profile