

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './screens/user/Register';
import Login from './screens/user/Login';
import AccountCreated from './screens/user/AccountCreated';
import EmailVerified from './screens/user/EmailVerified';
import JobList from './screens/job/JobList';
import JobDetailScreen from './screens/job/JobDetails';

import ProfileSetup from './screens/user/ProfileSetup';

import { useSelector } from 'react-redux';
import ProtectedRoute from './utils/ProtectedRoute';
import { Container } from '@mui/material';
import ChooseSetupRoute from './screens/user/JobSeeker/ChooseSetupRoute';
import ForgotPassword from './screens/user/ForgotPassword';
import ResetPassword from './screens/user/ResetPassword';






function App() {


  const { userInfo } = useSelector((state) => state.userLogin);




  return (
    <Router>

      <Container>

        <Routes>
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/account-created' element={<AccountCreated />} />
          <Route path='/auth/activate/:activationToken' element={<EmailVerified />} />
          <Route path='/choose-setup-route' element={<ChooseSetupRoute />} />
          <Route path='/' element={<JobList />} />

          <Route path='/job/:id/applicants' element={<ProtectedRoute item="applicants">
            <ProfileSetup />
          </ProtectedRoute>} />
          <Route path='/job/:id/applicant/:applicantId' element={<ProtectedRoute item="applicant">
            <ProfileSetup />
          </ProtectedRoute>} />
          <Route path='/job/:id' element={userInfo ?
            <ProtectedRoute item="job-detail">
              <ProfileSetup />
            </ProtectedRoute> : <JobDetailScreen />} />
          <Route path='/dashboard' element={
            <ProtectedRoute item="dashboard">
              <ProfileSetup />
            </ProtectedRoute>
          } />

          <Route path='/edit-profile' element={
            <ProtectedRoute item="edit-profile">
              <ProfileSetup />
            </ProtectedRoute>
          } />
          <Route path='/applications' element={
            <ProtectedRoute item="applications">
              <ProfileSetup />
            </ProtectedRoute>} />
          <Route path='/profile' element={
            <ProtectedRoute item="profile">
              <ProfileSetup />
            </ProtectedRoute>
          } />
          <Route path='/profile/education/:educationId' element={
            <ProtectedRoute item="education">
              <ProfileSetup />
            </ProtectedRoute>
          } />
          <Route path='/profile/experience/:experienceId' element={
            <ProtectedRoute item="experience">
              <ProfileSetup />
            </ProtectedRoute>
          } />
          <Route path='/jobs' element={
            <ProtectedRoute item="jobs">
              <ProfileSetup />
            </ProtectedRoute>
          } />
          <Route path='/billing' element={
            <ProtectedRoute item="billing">
              <ProfileSetup />
            </ProtectedRoute>
          } />
          <Route path='/settings' element={
            <ProtectedRoute item="settings">
              <ProfileSetup />
            </ProtectedRoute>
          } />

          <Route path='/support-ticket' element={
            <ProtectedRoute item="support-ticket">
              <ProfileSetup />
            </ProtectedRoute>
          } />

          <Route path='/job/:id/edit' element={
            <ProtectedRoute item="edit-job">
              <ProfileSetup />
            </ProtectedRoute>
          } />
          <Route path='/job/:id/apply' element={
            <ProtectedRoute item="apply">
              <ProfileSetup />
            </ProtectedRoute>
          } />

          <Route path='/seeker/:id' element={
            <ProtectedRoute item="jobseeker">
              <ProfileSetup />
            </ProtectedRoute>
          } />

          <Route path='/employer/:id' element={
            <ProtectedRoute item="employer">
              <ProfileSetup />
            </ProtectedRoute>
          } />

          <Route path='/users' element={
            <ProtectedRoute item="users">
              <ProfileSetup />
            </ProtectedRoute>
          } />

          <Route path='/update-password' element={
            <ProtectedRoute item="update-password">
              <ProfileSetup />
            </ProtectedRoute>
          } />

          <Route
            path='/auth/forgot-password'
            element={<ForgotPassword />}

          />
          <Route
            path='/auth/reset-password/:token'
            element={<ResetPassword />}

          />


        </Routes>

      </Container>

    </Router>
  )
}

export default App
