import { Fragment, useEffect } from "react";
import { getUserDetails, setItem } from "../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Box, Button, Chip, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import Moment from "react-moment";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast, ToastContainer } from "react-toastify";


const JobSeeker = () => {
    const { userInfo } = useSelector((state) => state.userLogin);

    const { user, loading } = useSelector((state) => state.userDetails);

    const { id } = useParams();



    const dispatch = useDispatch();


    const navigate = useNavigate()

    const viewResume = async (id) => {
        try {
            const response = await fetch(`/api/upload/view/resume/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userInfo.token}`,
                    'Content-Type': 'application/pdf',
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            } else {
                const errorData = await response.json();
                console.error('Failed to fetch resume:', errorData.msg);
                return toast('Failed to fetch resume', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        } catch (error) {
            console.error('Error fetching resume:', error);
            // Optionally, display an error message to the user
        }
    };




    useEffect(() => {
        if (!userInfo) {
            navigate("/login")

        }
        else if ((!user || user?._id !== id)) {
            dispatch(getUserDetails(id));
        }

        console.log(user)



    }, [navigate, dispatch, userInfo, user, id])
    return (
        <Grid container width="100%" >


            <Grid xs={2}></Grid>
            <Grid xs={8} >
                <ToastContainer />

                {loading ? <Typography><CircularProgress /></Typography> : (
                    <Fragment>


                        <Grid container my={3}>
                            <Grid xs={5} style={{ display: "flex", alignItems: "center" }}>
                                <Avatar src={user.avatar} sx={{ width: 120, height: 120 }} alt={user.firstname} />
                            </Grid>
                            <Grid xs={7}>
                                <Typography variant="h6" fontWeight={600}>{user?.firstname} {user?.lastname}</Typography>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", my: 1, fontSize: "16px" }}>
                                    <Typography sx={{ mr: 1 }}><AccountCircleOutlinedIcon /></Typography>
                                    <Typography>{user?.preferredTitle}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", my: 1 }}>
                                    <Typography sx={{ mr: 1, }}><EmailOutlinedIcon /></Typography>
                                    <Typography>{user.email}</Typography>
                                </Box>

                                <Typography><span style={{ fontWeight: 600 }}>Profile ID:</span> {user?.profileId}</Typography>

                            </Grid>

                        </Grid>

                        <Typography variant="h6">Personal Information</Typography>

                        <Box sx={{
                            border: "1px solid #ebebeb",
                            px: 5, py: 2, my: 4, borderRadius: "10px"
                        }}>
                            <Box>
                                <TextField
                                    margin="normal"
                                    value={user.firstname}
                                    fullWidth
                                    required
                                    label="First Name"

                                    autoComplete="First Name"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={user.lastname}
                                    label="Last name"

                                    autoComplete="Last name"
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"

                                    fullWidth
                                    type="number"


                                    label="Phone Number"
                                    value={user?.phone}
                                    sx={{ mb: 2 }}
                                    autoComplete="Phone Number"
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={user?.preferredTitle}
                                    label="Preferred Title"

                                    autoComplete="Preferred Title"
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={user?.stateOfResidence}
                                    label="State Of Residence"

                                    autoComplete="State Of Residence"
                                    autoFocus
                                />





                            </Box>
                        </Box>
                        <Typography variant="h6" mt={2}>Education Information</Typography>
                        {user.education?.length > 0 && user?.education?.map(edu => (
                            <Box sx={{
                                display: "flex", justifyContent: "space-between",
                                borderRadius: "10px", p: 3,
                                mb: 4,
                                border: "1px solid #eaeaea"
                            }}>
                                <div >
                                    <Typography variant="h6">{edu.name}</Typography>
                                    <Typography>{edu.field}</Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

                                    <Typography>
                                        <Moment format="MMM YYYY">{Date.parse(edu.date_start)}</Moment> -
                                        <Moment format="MMM YYYY">{Date.parse(edu.date_end)}</Moment>
                                    </Typography>
                                </div>
                            </Box>

                        ))}

                        <Typography variant="h6">Work Experience</Typography>
                        {user.experience && user.experience?.map(exp => (
                            <Box sx={{
                                display: "flex", justifyContent: "space-between", borderRadius: "10px", p: 3,
                                mb: 4, border: "1px solid #eaeaea"
                            }}>
                                <div>
                                    <Typography variant="h6">{exp.organization}</Typography>
                                    <Typography>{exp.title}</Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

                                    <Typography>
                                        <Moment format="MMM YYYY">{Date.parse(exp.date_start)}</Moment> -
                                        <Moment format="MMM YYYY">{Date.parse(exp.date_end)}</Moment>
                                    </Typography>
                                </div>
                            </Box>
                        ))}


                        <Typography variant="h6">Skill</Typography>
                        {user?.skills?.length > 0 && (
                            <Box sx={{
                                p: 3, border: "1px solid #eaeaea", borderRadius: "10px",
                                mb: 4
                            }}>
                                <Stack direction="row" spacing={1}>
                                    {user?.skills?.slice(0, 5).map(skill => (
                                        <Chip key={skill} label={skill} variant="outlined" />
                                    ))}

                                </Stack>
                            </Box>

                        )}

                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
                            <Button
                                variant="text"
                                startIcon={<KeyboardBackspaceOutlinedIcon />}
                                onClick={userInfo.isAdmin ? () => {

                                    dispatch(setItem("users"))
                                    dispatch(getUserDetails("profile"))
                                    navigate("/users")
                                } : () => {

                                    dispatch(setItem("profile"))
                                    dispatch(getUserDetails("profile"))
                                    navigate("/profile")
                                }}
                            >Back</Button>

                            {user?.resume && (

                                <Button
                                    variant="outlined"
                                    startIcon={<VisibilityIcon />}
                                    size="small"
                                    sx={{ fontSize: "12px" }}
                                    onClick={() => viewResume(user._id)
                                    }
                                >View Resume</Button>


                            )}

                        </Box>

                    </Fragment>
                )}
            </Grid>
            <Grid xs={2}></Grid>


        </Grid>
    )
}

export default JobSeeker